import { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import ItemDetail from "./ItemDetail";
import Cart from "./Cart";
import Options from "./Options";
import Info from "./Info";
import Carousel from "./Carousel";
import debounce from "lodash.debounce";
// Function to get cart items from localStorage or return an empty array if it doesn't exist
const getStoredCartItems = () => {
  const storedItems = localStorage.getItem("cartItems");
  return storedItems ? JSON.parse(storedItems) : [];
};

const Home = ({ types, items, categories, settings, images }) => {
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [ItemId ,setItemId] = useState();
  const [cart, setCart] = useState(getStoredCartItems);
  const [options, setOptions] = useState([]);
  const [showItemDetail, setShowItemDetail] = useState(false);
  const [visibleH3, setVisibleH3] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [item, setItem] = useState();
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  let price = 0;

  
  window.onclick = function(event) {
    if (event.target === document.querySelector('.options-wrapper-back') || event.target === document.querySelector('.options-item-title')) {
        setShowTypeModal(false)
    }
  }

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cart));
  }, [cart]);


  useEffect(() => {
    const scrollHandler = () => {
      if (isScrolling) return;

      const h3Elements = document.querySelectorAll(".food-carousel-category");

      h3Elements.forEach((h3Element) => {
        const h3Rect = h3Element.getBoundingClientRect();

        if (
          h3Rect.top >= 0 &&
          h3Rect.left >= 0 &&
          h3Rect.bottom <= window.innerHeight - 200 &&
          h3Rect.right <= window.innerWidth
        ) {
          // The h3 element is in view, set it as the visibleH3
          setVisibleH3(h3Element);
          return; // Stop checking further h3 elements
        }
      });

      // Get all elements with the class name "categories-item"
      const allCategories = document.querySelectorAll(".categories-item");

      // Check if the visible h3 tag's data-category-id matches any element's data-id
      if (visibleH3) {
        const visibleH3CategoryId = visibleH3.getAttribute("data-category-id");

        allCategories.forEach((categoryElement) => {
          const categoryDataId = categoryElement.getAttribute("data-id");
          if (visibleH3CategoryId === categoryDataId) {
            categoryElement.classList.add("categories-item--active");
            // Scroll the container to make the category element visible
            const categoriesWrapper = document.querySelector(
              ".categories-wrapper"
            );
            const elementOffsetLeft = categoryElement.offsetLeft;
            categoriesWrapper.scrollTo({
              left: elementOffsetLeft - categoriesWrapper.offsetWidth / 2,
              behavior: "smooth",
            });
          } else {
            categoryElement.classList.remove("categories-item--active");
          }
        });
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [visibleH3, isScrolling]);


  const categoryItemClick = debounce((event) => {
    const clickedCategoryItem = event.target.closest(".categories-item");
    if (!clickedCategoryItem && isScrolling) return;
    const clickedCategoryId = clickedCategoryItem.getAttribute("data-id");

    const matchingCarouselCategory = document.querySelector(
      `.food-card-carousel[data-category-id="${clickedCategoryId}"]`
    );

    if (matchingCarouselCategory) {
      document.querySelectorAll(".categories-item").forEach((el) => {
        if (el !== clickedCategoryItem) {
          el.classList.remove("categories-item--active");
        }
      });

      setIsScrolling(true);
      matchingCarouselCategory.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setTimeout(() => {
        clickedCategoryItem.classList.add("categories-item--active");
        setIsScrolling(false);
      }, 1000);
    }
  },200, { leading: true });


  window.onpopstate = (event) => {
    setShowItemDetail(event.state?.showItemDetail || false);
    setShowCart(event.state?.showCart || false);
    setShowInfo(event.state?.showInfo || false);
    setShowTypeModal(event.state?.showTypeModal || false);
    
  };

  const ItemTypeCheck = (id, quantity) => {
    setSelectedQuantity(quantity ? quantity : null);
    setItemId(id);
    var getItem = items.find((item) => item.id === id);
    setItem(getItem);
    var checkItem = types.find((o) => o.item === id);
    if (checkItem) {
      const updatedOptions = types
        .filter((item) => item.item === id)
        .map((item) => ({
          id: item.id,
          title: item.title,
          price: item.price,
          number: item.number,
          item: id,
          checked: false,
        }));
      setOptions(updatedOptions);
      setShowTypeModal(true);
    } else {
      addToCart(id, quantity);
    }
  };

  const addToCart = (id, quantity) => {
    var item = items.find((item) => item.id === id);
    var itemIndex = items.findIndex((item) => item.id === id);
    [...items][itemIndex].added = true;
    const itemHasType = options.some((option) => option.item === id);
    const hasCheckedOption = options.some((option) => option.checked);
    const optionsTotal = options.reduce(
      (total, item) => (item.checked ? total + item.price : total),
      0
    );
    const optionsNumber = options.reduce(
      (total, item) => (item.checked ? total + item.number : total),
      0
    );

    if (!itemHasType && quantity) {
      price = quantity * item.price;
    }

    if (!itemHasType && !quantity) {
      price = item.price;
    }

    if (!item.optional_types && itemHasType) {
      price = optionsTotal;
    }
    if (item.optional_types && itemHasType) {
      if (optionsTotal > 0 || optionsNumber > 0){
        price = item.price * optionsNumber + optionsTotal;
      }

      else{
        price = item.price
      }
      
    }

    if (!cart.find((item) => item.id === id)) {
      setCart([
        {
          id: item.id,
          title: item.title,
          img: item.img,
          price: price,
          first: item.first,
          number:
            hasCheckedOption && itemHasType
              ? optionsNumber
              : quantity
              ? quantity
              : 1,
          added: true,
          type: itemHasType ? options : [],
        },
        ...cart,
      ]);
      setShowTypeModal(false);
    }
  };

  const handleShowCart = () => {
    window.scrollTo(0, 0);
    setShowCart(true);
    window.history.pushState({ setShowCart: true }, "", "");
  };

  const handleShowInfo = () => {
    window.scrollTo(0, 0);
    setShowInfo(true);
    window.history.pushState({ setShowInfo: true }, "", "");
  };

  const handleItemClick = (id) => (event) => {
    if (event.target === event.currentTarget) {
      window.scrollTo(0, 0);
      var getItem = items.find((item) => item.id === id);
      setItem(getItem);
      setShowItemDetail(true);
      setShowCart(false)
      window.history.pushState({ showItemDetail: true }, "", "");
    }
  };

  const addItemNumber = (id) => {
    var updatedNotes = [...cart];
    var itemIndex = cart.findIndex((item) => item.id === id);
    var item = items.find((item) => item.id === id);
    updatedNotes[itemIndex].number += 1;
    updatedNotes[itemIndex].price += item.price;
    setCart(updatedNotes);
  };

  const reduceItemNumber = (id) => {
    var updatedNotes = [...cart];
    var itemIndex = cart.findIndex((item) => item.id === id);
    var item = items.find((item) => item.id === id);
    updatedNotes[itemIndex].number -= 1;
    updatedNotes[itemIndex].price -= item.price;
    setCart(updatedNotes);
  };

  const removeItemFromNotes = (id) => {
    var itemIndex = items.findIndex((item) => item.id === id);
    [...items][itemIndex].added = false;
    setCart(cart.filter((item) => item.id !== id));
    if (cart.length === 1) {
      setTimeout(() => {
        setShowCart(false);
      }, 300);
    }
  };

  const toggleOptionCheck = (e, typeId) => {
    var item = options.find((item) => item.id === typeId);
    const checked = !item.checked;
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === typeId
          ? { ...option, checked }
          : option
      )
    );
  };

  const cheapestTypePrice = (id) => {
    var itemType = types.filter((i) => i.item === id)[0];
    return itemType;
  };

  const checkIfItemAddedToCart = (id) => {
    return cart.some((item) => item.id === id);
  };


  return (
    <>
    {showInfo ? 
    <Info settings={settings} items={items} images={images} onHide={() => setShowInfo(false)}
    /> : 
    <>
    {showCart ? (
        <Cart
          onHide={() => setShowCart(false)}
          setShowCart={setShowCart}
          setCart={setCart}
          cart={cart}
          types={types}
          items={items}
          removeItemFromNotes={removeItemFromNotes}
          addItemNumber={addItemNumber}
          reduceItemNumber={reduceItemNumber}
          handleItemClick={handleItemClick}
          settings={settings}
        />
      ) : (
        <>
          {showTypeModal ? (
            <Options
              onHide={() => setShowTypeModal(false)}
              setShowTypeModal={setShowTypeModal}
              options={options}
              setOptions={setOptions}
              types={types}
              addToCart={addToCart}
              toggleOptionCheck={toggleOptionCheck}
              item={item}
            />
          ) : (
            ""
          )}
          {showItemDetail ? (
            <ItemDetail
              item={item}
              onHide={() => setShowItemDetail(false)}
              setShowItemDetail={setShowItemDetail}
              showItmDetail={showItemDetail}
              setShowCart={setShowCart}
              cart={cart}
              items={items}
              addToCart={ItemTypeCheck}
              removeItemFromNotes={removeItemFromNotes}
              handleShowCart={handleShowCart}
              cheapestTypePrice={cheapestTypePrice}
              options={options}
              types={types}
              checkIfItemAddedToCart={checkIfItemAddedToCart}
              categories={categories}
            />
          ) : (
            <div className="container">
              <Header
                cart={cart}
                handleShowCart={handleShowCart}
                handleShowInfo={handleShowInfo}
                settings={settings}
              />
              <div className="sticky-top ">
                <div className="row  categories-wrapper  mt-3 flex-nowrap  overflow-scroll ">
                  <div className="ms-4  w-auto text-center p-0 d-flex justify-content-center flex-column align-items-center">
                    <i className="bi-chevron-double-right left-slide-animation text-main"></i>
                    <h6 className="mt-3 text-main">دسته‌ بندی‌ها</h6>
                  </div>
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      data-id={category.id}
                      onClick={categoryItemClick}
                      className="categories-item text-center p-3 d-flex justify-content-evenly flex-column align-items-center"
                    >
                      <img
                        src={`https://my.dinez.ir/static/category_icons/${category.img}`}
                        width={45}
                        alt=""
                      />
                      <h6 className="mt-3 ">{category.title}</h6>
                    </div>
                  ))}
                </div>
              </div>
              {categories.map((category) => (
                <div key={category.id}>
                  <Carousel
                  category={category}
                  items={items}
                  handleItemClick={handleItemClick}
                  checkIfItemAddedToCart={checkIfItemAddedToCart}
                  removeItemFromNotes={removeItemFromNotes}
                  cheapestTypePrice={cheapestTypePrice}
                  ItemTypeCheck={ItemTypeCheck}
                  />
                </div>
              ))}
              <Navbar handleShowInfo={handleShowInfo} cart={cart} onclick={handleShowCart} settings={settings} />
            </div>
          )}
        </>
      )}
    </>
    }
    </>
  );
};

export default Home;
