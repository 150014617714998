import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import added from "../images/added.png";
import cartIcon from '../images/cart.svg'
import arrowLeft from '../images/arrow-left.svg'

const ItemDetail = ({
  onBackClick,
  cheapestTypePrice,
  item,
  addToCart,
  removeItemFromNotes,
  handleShowCart,
  types,
  checkIfItemAddedToCart,
  categories
}) => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  var itemIngredient = item.ingredient?.split('-')
  

  useEffect(() => {
    const handleBrowserBack = () => {
      onBackClick();
    };
    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [onBackClick]);

  const addQuantity = () => {
    setQuantity(quantity + 1);
  };

  const reduceQuantity = () => {
    setQuantity(quantity > 1 ? quantity - 1 : 1);
  };

  const getItemCategory = () => {
    return categories.find((i) => i.id === item.category).title;
  }

  return (
    <div className="item-detail ">
      <div className="overflow-scroll row flex-nowrap mx-0 food-gallery position-relative">
        {checkIfItemAddedToCart(item.id) ? (
          <p
            style={{ right: "0" }}
            className={`cartadded bg-second round  w-auto cursor-pointer rounded-circle position-fixed m-2 top-0`}
          >
            <img
              src={added}
              width={50}
              height={70}
              style={{ objectFit: "contain",  }}
              
            />
          </p>
        ) : (
          ""
        )}
          <p
            style={{ left: "0", height: '70px', width: '70px' }}
            onClick={() => navigate(-1)}
            className={`d-flex flex-column justify-content-center bg-second rounded-circle align-items-center cursor-pointer  position-fixed m-2 top-0`}
          >
          <img src={arrowLeft} width={40} height={40} />
          </p>
        <div className="col-12 image p-0">
          <img
            src={`${item.img}`}
            className="w-100"
            height={450}
            alt=""
          />
        </div>
      </div>

      <div className="bg-bg content-wrapper pt-3 p-2">
        <div className="container">
          <div className=" position-relative d-flex align-items-center justify-content-between mb-3  ">
            <h2 className="item-title">{item.title}</h2>
            <div className="d-flex flex-column align-items-center justify-content-between w-25">
              <b className="price">
                {cheapestTypePrice(item.id) && !item.optional_types &&
                cheapestTypePrice(item.id).price !== 0
                  ? cheapestTypePrice(item.id).price
                  : quantity > 1
                  ? quantity * item.price
                  : item.price}
              </b>
              <span
                className="me-1 mt-1 text-muted "
                style={{ fontSize: "12px" }}
              >
                هزار تومان
              </span>
            </div>
          </div>
          <p className=" d-inline-block text-muted bg-second category ms-2">
            {getItemCategory()}
          </p>
          {item.baking_time ?

          <p className=" d-inline-block text-muted bg-second  category ms-2">
           {item.baking_time}<i className="bi-alarm me-1"></i>
          </p>
          : ''}

          {item.ingredient ?
            <>
            <h5 className="text-secondary text-main my-2">ترکیبات:</h5>
            <div className="row flex-nowrap me-0 overflow-scroll ingradients  ps-2">
                {itemIngredient.map((i) => (<div className="w-auto ingradients-item  py-2 px-3">{i}</div>))}
            </div>
          </>
          : ''}
        {item.desc ?
          <>
          <h5 className="text-secondary text-main mt-3 mb-2">توضیحات:</h5>
          <p>{item.desc}</p>
          </>
        : ''}
        </div>
      </div>

      <div className="add-to-cart-wrapper-space">
        <div className="item-detail px-3 d-flex add-to-cart-wrapper fixed-bottom pt-2 pb-3 justify-content-between">
          <button
            className={`${
              checkIfItemAddedToCart(item.id)
                ? "bg-second text-main"
                : "bg-main text-white"
            } round border-0 p-4 position-relative ${
              types.some((option) => option.item === item.id) ? "w-100" : ""
            } add-to-cart`}
            onClick={() =>
              checkIfItemAddedToCart(item.id)
                ? removeItemFromNotes(item.id)
                : addToCart(item.id, quantity)
            }
          >
            {checkIfItemAddedToCart(item.id)
              ? "حذف از سفارش"
              : ` افزودن به سفارش `}
          </button>
          {checkIfItemAddedToCart(item.id) ? (
            <div
              className="text-center quantity-wrapper bg-main me-3"
              onClick={handleShowCart}
            >
            <img src={cartIcon} width={35} height={35} alt="" />

            </div>
          ) : (
            <div
              className={`d-flex ${
                types.some((option) => option.item === item.id) ? "d-none" : ""
              }   quantity-wrapper bg-second align-items-center justify-content-between`}
            >
              <i
                onClick={addQuantity}
                className="bi-plus cart-plus cursor-pointer"
              ></i>
              <b className="quantity  my-2">{quantity}</b>
              <i
                onClick={reduceQuantity}
                className="bi-dash cart-plus cursor-pointer"
              ></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
