import React, { useEffect, useState } from "react";

const Options = ({
  setShowTypeModal,
  onBackClick,

  item,
  options,
  setOptions,
  types,
  addToCart,
  toggleOptionCheck,
}) => {
  const isItemDetail = document.querySelector('.item-detail') ? true : false;

  useEffect(() => {
    const handleBrowserBack = () => {
      onBackClick();
    };
    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [onBackClick]);

  // ** Add to number of item's type in the Options **
  const addOptionTypeNumber = (typeId) => {
    var getType = types.find((item) => item.id === typeId);
    const updatedOptions = [...options];
    const option = updatedOptions.find((option) => option.id === typeId);
    if (option) {
      option.number += 1;
      option.price += getType.price;
    }
    setOptions(updatedOptions);
  };
  

  // ** reduce number of item's type in the Options **
  const reduceOptionTypeNumber = (typeId) => {
    var getType = types.find((item) => item.id === typeId);
    const updatedOptions = [...options];
    const option = updatedOptions.find((option) => option.id === typeId);
    if (option) {
      option.number -= 1;
      option.price -= getType.price;
    }
    setOptions(updatedOptions);
  };

  return (
    <div className="options-wrapper-back">
      <div className="options-item-title w-75 text-center">
      <img
        className="rounded-circle  "
        src={`${item.img}`}
        width={100}
        height={100}
        alt=""
      />
      <h2 className="mt-4">{item.title}</h2>
      </div>
      <div className={`bg-second d-flex flex-column justify-content-between  pt-4 fixed-bottom options-wrapper ${isItemDetail ? 'item-detail' : 'container'} `}>
        <div className="px-3 mb-4 text-center">
        {item.optional_types ? 
          <h5 className="mb-3 text-main">(( اختیاری ))</h5>
        : ''}
          <h5 className="m-0"> <span className="text-main">{item.options_title}</span> و <span className="text-main">تعداد</span> غذا را انتخاب کنید</h5>
        </div>
        <div className="px-3 tt">
          {options.map((type) => (
            <div
              key={type.id}
              className={`d-flex justify-content-start  bg-bg ${
                type.checked ? "border-main" : ""
              }  option-item-wrapper round mb-2 p-3 align-items-center  position-relative`}
            >
              <input
                type="checkbox"
                className="option-item-checkbox"
                checked={type.checked}
                onChange={(e) => toggleOptionCheck(e, type.id)}
              />
              <div
                onClick={(e) => toggleOptionCheck(e, type.id)}
                className="d-flex mx-2 w-100 justify-content-between bg-second round p-3 align-items-center"
              >
                <h6>{type.title}</h6>
              <div>
                <small>
                  {type.price !== 0 ? (
                    <span>{type.price.toLocaleString("IR")} {item.optional_types ? <i className="bi-plus"></i> : ''} تومان</span>
                  ) : (
                    ""
                  )}
                </small>
              <small className="quantity me-1">({type.number})</small>


              </div>
              </div>
              <div
                className="position-absolute d-flex flex-column justify-content-between align-items-center"
                style={{ left: "-8px", top: "18%" }}
              >
                <i
                  onClick={() => addOptionTypeNumber(type.id)}
                  className="bi-plus mb-2 cart-plus bg-second border border-secondary cursor-pointer"
                ></i>
                <i
                  onClick={() => reduceOptionTypeNumber(type.id)}
                  className={`${
                    type.number > 1
                      ? "bi-dash cart-plus cursor-pointer bg-second border border-secondary"
                      : " "
                  } `}
                ></i>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex align-items-center justify-content-between p-3  bg-second  options-add-wrapper">
          {options.some((option) => option.checked) ? (
            <button
              className="add-to-cart bg-main round w-75"
              onClick={() => addToCart(item.id)}
            >
              افزودن به سفارش
            </button>
          ) : item.optional_types ? (
            <button
              className="add-to-cart bg-bg text-main border-main border round w-75"
              onClick={() => addToCart(item.id)}
            >
              افزودن بدون تغییر 
            </button>
          ) : (
            <button className="add-to-cart bg-bg text-main round w-75">
              لطفا انتخاب کنید
            </button>
          )}
          <h5 className=" d-inline-block text-center w-25">
            <b>
              {options
                .reduce(
                  (total, item) => (item.checked ? total + item.price : total),
                  0
                )
                .toLocaleString("IR")}
            </b>{" "}
            <br /> <small>تومان</small>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Options;
