import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowLeft from '../images/arrow-left.svg'
import callIcon from '../images/call.svg'
import trashcanIcon from '../images/trashcan.svg'

const Cart = ({
  onBackClick,
  cart,
  setCart,
  items,
  setShowCart,
  reduceItemNumber,
  addItemNumber,
  removeItemFromNotes,
  types,
  settings,
  handleItemClick
}) => {
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const updatedCart = cart.filter((cartItem) => {
        const itemInItems = items.find((item) => item.id === cartItem.id);
        return itemInItems && !itemInItems.is_finished;
      });
      setCart(updatedCart);
      setIsInitialized(true);
    }
  }, [cart, items, setCart, isInitialized]);

  useEffect(() => {
    const handleBrowserBack = () => {
      onBackClick();
    };
    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [onBackClick]);

  const clearNotes = () => {
    setCart([]);
    items.forEach((item) => (item.added = false));
    setTimeout(() => {
      setShowCart(false);
    }, 300);
  };

  const totalPrice = Object.values(cart).reduce((total, item) => {
    if (
      item.type.length > 0 &&
      !items.find((i) => i.id === item.id)?.optional_types
    ) {
      const typeTotal = item.type.reduce((typeTotal, type) => {
        if (type.checked) {
          return typeTotal + type.price;  
        } else {
          return typeTotal;
        }
      }, 0);
      return total + typeTotal * 1000;
    } else {
      return total + item.price * 1000;
    }
  }, 0);

  const addCartTypeNumber = (typeId, cartItemId) => {
    // get the type from types which has the real price
    var getType = types.find((item) => item.id === typeId);
    const updatedNotes = [...cart];
    const cartItem = updatedNotes.find((item) => item.id === cartItemId);
    const cartItemType = cartItem.type.find((type) => type.id === typeId);
    if (cartItemType) {
      cartItemType.number += 1;
      cartItemType.price += getType.price;
      if (items.find((i) => i.id === cartItemId)?.optional_types) {
        cartItem.price += getType.price + items.find((i) => i.id === cartItemId)?.price;
      }
    }
    setCart(updatedNotes);
  };

  const reduceCartTypeNumber = (typeId, cartItemId) => {
    var getType = types.find((item) => item.id === typeId);
    const updatedNotes = [...cart];
    const cartItem = updatedNotes.find((item) => item.id === cartItemId);
    const cartItemType = cartItem.type.find((type) => type.id === typeId);
    if (cartItemType) {
      cartItemType.number -= 1;
      cartItemType.price -= getType.price;
      
      if (items.find((i) => i.id === cartItemId)?.optional_types) {
        cartItem.price -= getType.price + items.find((i) => i.id === cartItemId)?.price;
        cartItem.number = cartItem.number > 1 ? cartItem.number - 1 : 1;
        cartItem.price = cartItem.price === 0 ? items.find((i) => i.id === cartItemId)?.price : cartItem.price
      }
      // remove the option from item
      if (cartItemType.number === 0) {
        cartItem.type = cartItem.type.map((type) =>
        type.id === typeId ? { ...type, checked: false } : type
      );
        if (cartItem.type.length === 0 || cartItem.type.every((type) => type.checked === false) && !items.find((i) => i.id === cartItemId)?.optional_types) {
          updatedNotes.splice(updatedNotes.findIndex((item) => item.id === cartItemId),1);
          [...items][items.findIndex((item) => item.id === cartItemId)].added = false;
        }
      }
    }
    setCart(updatedNotes);
  };


  const getHumanizePrice = (itemPrice) => {
    var ip = itemPrice * 1000
    return ip.toLocaleString('IR')
  }

  return (
    <>
      <div className="container">
        <div className="d-flex flex-wrap  mt-4 mb-3 justify-content-between align-items-center">
          <h3 className="font-black m-0">سفارش شما</h3>
          <div className="">
          <p
              onClick={() => window.open(`tel:${settings.map(i => i.phone)}`)}
              className="bg-second p-3 ms-2 rounded d-inline-block cursor-pointer"
            >
            <img src={callIcon} width={20} height={20} alt="" />

            </p>
            <p
              onClick={clearNotes}
              className="bg-second p-3  rounded d-inline-block cursor-pointer"
            >
                <img src={trashcanIcon} width={20} height={20} alt="" />

            </p>
            <p
              onClick={() => navigate(-1)}
              className="bg-second p-3 rounded d-inline-block me-2 cursor-pointer"
            >
            <img src={arrowLeft} width={20} height={20} alt="" />

            </p>
          </div>
        </div>
          {cart.map((item) => ( items.find((i) => i.id === item.id) ? 
              <div
                key={item.id}
                className=" p-3 cart-item-wrapper mt-3 position-relative"
                
              >
                <div className="bg-second d-flex   justify-content-start" >
                  <i
                    onClick={() => removeItemFromNotes(item.id)}
                    className="bi-x-circle text-muted cursor-pointer position-absolute top-0"
                    style={{ left: "0", fontSize: "20px" }}
                  ></i>
                  <img
                    className="round cursor-pointer cart-item-img  ms-3"
                    src={`${item.img}`}
                    width={100}
                    height={100}
                    alt="food image"
                    onClick={(event) => handleItemClick(item.id)(event)}
                  />
                  <div className="d-flex flex-column w-100 justify-content-between">
                    <h6 className="cursor-pointer"
                    onClick={(event) => handleItemClick(item.id)(event)}
                    >{item.title}</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      {item.type.length > 0 && 
                      !items.find((i) => i.id === item.id)?.optional_types ? (
                        <>
                          <span>
                            {item.type.reduce(
                              (total, item) =>
                                item.checked ? total + item.number : total,
                              0
                            )}
                            <small className="me-1">عدد</small>
                          </span>
                          <h6 className="m-0 text-main">
                            {item.type.reduce(
                              (total, item) =>
                                item.checked ? total + item.price : total,
                              0
                            )}{" "}
                            تومان
                          </h6>
                        </>
                      ) : (
                        <>
                          {items.find((i) => i.id === item.id)?.optional_types &&
                          item.type.reduce(
                            (total, item) =>
                              item.checked ? total + item.number : total,
                            0
                          ) !== 0 ? (
                            <span>
                              {item.type.reduce(
                                (total, item) =>
                                  item.checked ? total + item.number : total,
                                0
                              )}
                              <small className="me-1">عدد</small>
                            </span>
                          ) : (
                            <div className="">
                              <i
                                className="bi-plus cart-plus bg-bg"
                                onClick={() => addItemNumber(item.id)}
                              ></i>
                              <b
                                className="quantity  mx-2"
                                style={{ verticalAlign: "middle" }}
                              >
                                {item.number}
                              </b>
                              <i
                                className={`${
                                  item.number > 1
                                    ? "bi-dash cart-plus bg-bg"
                                    : " "
                                } `}
                                onClick={() =>
                                  item.number === 1
                                    ? removeItemFromNotes(item.id)
                                    : reduceItemNumber(item.id)
                                }
                              ></i>
                            </div>
                          )}

                          <h6 className="m-0 text-main">{getHumanizePrice(item.price)} تومان</h6>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {item.type.length > 0 && (
                  <>
                  <p className="mt-3 mb-2 small text-muted"><b className="text-main">{items.find((i) => i.id === item.id)?.options_title}</b> انتخاب شده: </p>
                    {item.type.map((type) =>
                      type.checked && type.item === item.id ? (
                        
                        <div
                          key={type.id}
                          className="bg-bg p-1 cart-item-type-wrapper rounded d-flex justify-content-between align-items-center"
                        >
                          <small className="m-0 w-25">{type.title}</small>
                          <div className="d-flex justify-content-between align-items-center w-25">
                            <i
                              className="bi-plus bg-second text-muted cart-plus"
                              onClick={() =>
                                addCartTypeNumber(type.id, type.item)
                              }
                            ></i>
                            <small
                              className="quantity  mx-2"
                              style={{ verticalAlign: "middle" }}
                            >
                              {type.number}
                            </small>
                            <i
                              className={`${
                                type.number > 1 ? "bi-dash " : "bi-x "
                              }  bg-second cart-plus text-muted`}
                              onClick={() =>
                                reduceCartTypeNumber(type.id, type.item)
                              }
                            ></i>
                          </div>
                          <small className="w-25 text-start">
                            {type.price.toLocaleString("IR")}{" "}
                            <small className="text-muted me-1">تومان</small>
                          </small>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </>
                )}
              </div>
            
          : ''))}
      </div>
      <div className="cart-total-wrapper-space">
        <div className="fixed-bottom container cart-total-wrapper d-flex flex-column justify-content-end pb-4 px-4">
          <div className="  d-flex justify-content-between align-items-center">
            <h5 className="m-0">جمع سفارش</h5>
            <h5 className="price m-0">
              <b>{totalPrice.toLocaleString("IR")}</b> تومان
            </h5>
          </div>
          <button className="bg-bg text-white border border-main mt-4 p-4 round w-100">
            سفارش خود را به مجموعه اعلام کنید
          </button>
        </div>
      </div>
    </>
  );
};

export default Cart;
