import React, { useRef } from 'react';
import RemoveItemIcon from "../images/added.png";
import outOfStock from "../images/outOfStock.png";
import plusIcon from '../images/plus.svg'
import trashcanIcon from '../images/trashcan.svg'
import arrowLeft from '../images/arrow-left.svg'
import arrowRight from '../images/arrow-right.svg'

const Carousel = ({category, items, ItemTypeCheck, cheapestTypePrice, removeItemFromNotes, checkIfItemAddedToCart, handleItemClick}) => {
    const carouselRef = useRef(null);
    const scrollCarousel = (direction) => {
        const carousel = carouselRef.current;
        if (carousel) {
          const itemWidth = carousel.querySelector('.col-9').offsetWidth;
          const scrollAmount = direction === 'next' ? -itemWidth * 2 : itemWidth * 2;
          carousel.scrollBy({
            left: scrollAmount,
            behavior: 'smooth',
          });
        }
      };
    
  return (
<>
<div className="d-flex justify-content-between mb-lg-4 align-items-center">
    <h3
      className="font-black mt-4 food-carousel-category"
      data-category-id={category.id}
    >
      {category.title}
    </h3>
    <div className="d-lg-flex d-none align-items-center">
      
      <div className="info-carousel-next bg-second cursor-pointer p-3  rounded-circle ms-3" onClick={() => scrollCarousel('next')}> <img src={arrowRight} width={30} height={30} /> </div>
      <div className="info-carousel-prev bg-second cursor-pointer p-3 rounded-circle" onClick={() => scrollCarousel('prev')}><img src={arrowLeft} width={30} height={30} /></div>
    </div>
    </div>
    <div
    className="food-card-carousel overflow-scroll row flex-nowrap py-3 align-items-center"
    data-category-id={category.id}
    ref={carouselRef}
    style={{scrollMarginTop: '35px'}}
  >
    {items.map((item) =>
      item.category === category.id ? (
        <div key={item.id} className="col-9 col-lg-3 food-card-col">
          <div
            className="d-flex flex-column position-relative food-card  justify-content-end"
            onClick={(event) => !item.is_finished ? handleItemClick(item.id)(event) : ''}
            style={{
              background: `url(${item.img})`,
            }}
          >
            <img
              onClick={(event) =>
                handleItemClick(item.id)(event)
              }
              width={60}
              className={`${
                checkIfItemAddedToCart(item.id) ? "" : "d-none"
              } position-absolute`}
              src={RemoveItemIcon}
              alt="RemoveItemIcon"
              style={{
                left: "50%",
                top: "30%",
                transform: "translate(-50%, -50%)",
              }}
            />
            <img
              onClick={(event) =>
                !item.is_finished ?
                handleItemClick(item.id)(event)
                : ''
              }
              width={150}
              className={`${
                item.is_finished ? "" : "d-none"
              } position-absolute`}
              src={outOfStock}
              alt=""
              style={{
                left: "50%",
                top: "30%",
                transform: "translate(-50%, -50%)",
              }}
            />
            <div
              onClick={(event) => 
                
                  !item.is_finished ?
                  handleItemClick(item.id)(event)
                  : ''
                
              }
              className={`${
                checkIfItemAddedToCart(item.id) || item.is_finished
                  ? "added-item-shadow d-flex flex-column food-card justify-content-end"
                  : ""
              }`}
            >
              {item.has_types ? 
              <i className='position-absolute top-0 m-3 bi-view-list card-options-badge pb-0 p-2 rounded' style={{left: '0'}} ></i>
              : ''}
              <div className="card-meta-wrapper mb-3 flex-column justify-content-between d-flex">
                <h6
                  onClick={(event) =>
                    !item.is_finished ?
                    handleItemClick(item.id)(event)
                    : ''
                  }
                  className="m-0 food-card--title"
                >
                  {item.title}
                </h6>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="m-0">
                    {cheapestTypePrice(item.id) && !item.optional_types &&
                    cheapestTypePrice(item.id).price !== 0 ? (
                      <>
                        <small
                          className="ms-1"
                          style={{ fontSize: "13px" }}
                        >
                          از
                        </small>
                        <b className="food-card--price">
                          {cheapestTypePrice(
                            item.id
                          ).price.toLocaleString("IR")}
                        </b>
                      </>
                    ) : (
                      <>{item.price.toLocaleString("IR")}</>
                    )}
                    <small
                      className="me-1"
                      style={{ fontSize: "13px" }}
                    >
                      تومان
                    </small>
                  </h5>
                  {checkIfItemAddedToCart(item.id) ? (
                    <img src={trashcanIcon} width={20} height={20} alt=""
                    onClick={() =>
                      removeItemFromNotes(item.id)
                    }
                    />

                  ) : (

                    <>
                    <img src={plusIcon} width={20} height={20} alt="" 
onClick={() => !item.is_finished ? ItemTypeCheck(item.id) : ''} />

                      </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null
    )}
  </div>

</>
  )
}

export default Carousel