import React from "react";
import cartIcon from '../images/cart.svg'
import infoIcon from '../images/info.svg'

const Header = ({cart, handleShowCart, handleShowInfo, settings}) => {
  return (
    <div className="header-wrapper d-flex align-items-center py-4  justify-content-between">
      <div className=" d-flex align-items-center">
        <img src={settings.map((i) => i.logo)} alt="logo" width={60} height={60} style={{objectFit: 'contain'}} className="rounded" />
        <div className="me-2 mt-2">
          <h1 className="font-black menu-name text-main">{settings.map((i) => i.brand)}</h1>
          <h6 style={{ fontSize: "14px" }}>
          {settings.map((i) => i.slogan)}
          </h6>
        </div>
      </div>
    <div className="d-none align-items-center d-lg-flex">

      <div
        className="ms-3 p-2 rounded-circle cursor-pointer text-center bell- "
        style={{ height: "54px", width: "60px" }}
        onClick={handleShowInfo}
        title="اطلاعات مجموعه"
      >

      <img src={infoIcon} width={40} height={40} alt="" />

      </div>
      <div className="position-relative" title="سبد خرید">
          <div
            className=" p-2 rounded-circle cursor-pointer  text-center bell-wrapp "
            style={{ height: "60px", width: "60px" }}
            onClick={handleShowCart}
          >
            <span className="text-main position-absolute bell-unread-lable">
            {cart.length > 0 ? (
                  <span className="cart-count bg-main text-white">{cart.length}</span>
              ) : (
                ""
              )}
            </span>
            <img src={cartIcon} width={45} height={45} alt="" />
          </div>
      </div>
      <div className=" me-3 text-center p-2"><h5>{settings.map((i) => i.phone)}</h5><h6>تماس با ما</h6></div>

    </div>
    <div
        className="p-3 rounded-circle text-center bell-wrapper d-lg-none "
        style={{ height: "60px", width: "60px" }}
        onClick={handleShowInfo}
      >
        <i
          style={{ fontSize: "23px", height: "16px" }}
          className="bi-info-lg  d-inline-block"
        ></i>
    </div>
    </div>
  );
};

export default Header;
