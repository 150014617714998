import "./index.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import SplashScreen from "./components/SplashScreen";
import { useEffect, useState } from "react";

function App() {
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [settings, setSettings] = useState([]);
  const [images, setImages] = useState([]);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    getItems();
  }, []);

 
  let getItems = async () => {
    let response = await fetch(`https://my.dinez.ir/api/fetch/demo/`);
    let data = await response.json();
    if (data.message !== "404") {
      setItems(data.items);
      setTypes(data.types);
      setCategories(data.categories);
      setSettings(data.settings);
      setImages(data.images);

    }
    if (data.message === "404") {
      setError(true);
    }
  };

  // sort types by price
  types.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  // Function to change the theme color
  const metaTag = document.querySelector('meta[name="theme-color"]');
  if (metaTag) {
    metaTag.setAttribute(
      "content",
      settings.map((i) => i.color)
    );
  }

  document.documentElement.style.setProperty(
    "--mainColor",
    settings.map((i) => i.color)
  );

  return (
    <div className="App">
      <SplashScreen />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              settings={settings}
              items={items}
              types={types}
              categories={categories}
              setItems={setItems}
              images={images}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
