import cartIcon from '../images/cart.svg'
import callIcon from '../images/call.svg'
import infoIcon from '../images/info.svg'

const Navbar = ({ onclick, cart, handleShowInfo, settings }) => {
  return (
    <>
      <div className="nav-space d-lg-none ">
        <div className="nav-wrapper px-4 py-2 fixed-bottom">
          <div className=" container  d-flex align-items-center justify-content-around">

            <div className="" onClick={() => window.open(`tel:${settings.map(i => i.phone)}`)}>
            <img src={callIcon} width={27} height={27} alt="" />
            </div>

            <div
              onClick={onclick}
              className="nav-cart-btn-wrapper mx-4 d-flex align-items-center position-relative justify-content-center cursor-pointer"
            >
              <img src={cartIcon} width={45} height={45} alt="" />
              {cart.length > 0 ? (
                <span className="cart-count bg-main text-white">{cart.length}</span>
              ) : (
                ""
              )}
            </div>

            <div className="cursor-pointer" onClick={handleShowInfo}>
            <img src={infoIcon} width={27} height={27} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
