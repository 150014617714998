import React, { useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import arrowLeft from '../images/arrow-left.svg'
import arrowLeft2 from '../images/arrow-left-2.svg'
import clockIcon from '../images/star.svg'
import starIcon from '../images/clock.svg'
import arrowRight from '../images/arrow-right.svg'

const Info = ({ settings, images, onBackClick}) => {
  const carouselRef = useRef(null);
  const navigate = useNavigate();

  const scrollCarousel = (direction) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const itemWidth = carousel.querySelector('.info-carousel-item').offsetWidth;
      const scrollAmount = direction === 'next' ? -itemWidth * 1 : itemWidth * 1;
      carousel.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    const handleBrowserBack = () => {
      onBackClick();
    };
    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [onBackClick]);

  return (
    <div className="container">
      <div className="info-head round mt-4 " >
        <div className='info-head-layer  d-flex justify-content-center flex-column px-5'>
        <div className="d-flex justify-content-between flex-column-reverse flex-lg-row  flex-wrap align-items-center">
          <div className="text-center text-lg-end">
            <h1 className="font-black mb-2 mt-4 mt-lg-0">{settings.map((i) => i.brand)}</h1>
            <h5>{settings.map((i) => i.slogan)}</h5>
          </div>
          <img src={settings.map((i) => i.logo)} alt="logo" style={{objectFit: 'contain'}} width={160} height={160} />
        </div>
        </div>
      </div>
      <div className="d-flex justify-content-around mt-3   flex-md-row flex-column align-items-center">
        <div className="bg-second align-items-center  cursor-pointer d-flex justify-content-between align-items-center  w-100 wo-lg-auto text-center ms-lg-3 mb-3  px-5 py-3 round" onClick={() => navigate(-1)}> 
          بازگشت به منو
          <img src={arrowLeft2} width={25} height={25} />
        </div>
        <div className="bg-second align-items-center  cursor-pointer px-5 py-3 d-flex justify-content-between  w-100 wo-lg-auto  ms-lg-3 mb-3 text-center round" >فعال از سال {settings.map((i) => i.established)}
        <img src={clockIcon} width={25} height={25} />
        </div>
        <div className="bg-second align-items-center cursor-pointer px-5 py-3  w-100 wo-lg-auto d-flex justify-content-between  mb-3 text-center round" >{settings.map((i) => i.open_hours)}
        <img src={starIcon} width={25} height={25} />
        </div>
      </div>
      {settings.find((i) => i.about) ? 
      <div className="mt-3 bg-second p-3 round">
        <h3 className="mb-4 text-main">درباره ما:</h3>
        {settings.map((i) => i.about)}
      </div>
      : ''}
      {images.length > 0 ?  
      <div className="mt-5">
        <div className="d-flex mb-4 align-items-center justify-content-between">
          
          <h3 className="text-main">تصاویر مجموعه:</h3>
          {images.length > 4 ? 
          <div className="d-flex">
            <div className="info-carousel-next bg-main p-2 cursor-pointer round ms-3" onClick={() => scrollCarousel('next')}> <img src={arrowRight} width={30} height={30} /></div>
            <div className="info-carousel-prev bg-main p-2 cursor-pointer  pb-2 round" onClick={() => scrollCarousel('prev')}> <img src={arrowLeft} width={30} height={30} /></div>
          </div>
            : ''}
        </div>
        <div className=" info-carousel overflow-scroll row flex-nowrap py-3 align-items-center" ref={carouselRef}>
        {images.map((image) => (
            <div key={image.id} className="col-lg-3 col-10 info-carousel-item">
              <img src={image.image} className="round w-100" height={300} alt="" />
            </div>
          ))}
        </div>
      </div>
      : ''}
      <div className="row justify-content-center align-items-center mt-5">
      {settings.find((i) => i.website) ? 

        <div className="col-lg-2 mb-3 mb-lg-0 col-6 cursor-pointer"
        onClick={() =>
          settings.map((i) => 
          window.open(`https://instagram.com/${i.website}`, "_blank")
          )
        }
        >
          <div className="bg-main round text-center p-3  flex-column d-flex ">
          <i className="bi-globe ms-2" style={{fontSize: '21px'}}></i>{settings.map((i) => i.website)}
          </div>
        </div>

        : ''}
        {settings.find((i) => i.instagram) ? 
        <div className="col-lg-2 mb-3 mb-lg-0 col-6 cursor-pointer" 
          onClick={() =>
            settings.map((i) => 
            window.open(`https://instagram.com/${i.instagram}`, "_blank")
            )
          }
          >
            
          <div className="bg-main round text-center p-3 flex-column d-flex " >
          <i className="bi-instagram  ms-2" style={{fontSize: '21px'}}></i> {settings.map((i) => i.instagram)}
          </div>
          
        </div>
        : ''}
        <div className="col-lg-2 mb-3 mb-lg-0 col-6 cursor-pointer" onClick={() => window.open(`tel:${settings.map(i => i.phone)}`)}>
          <div className="bg-main round text-center p-3 flex-column d-flex  ">
          <i className="bi-telephone-fill  ms-2" style={{fontSize: '21px'}}></i><b> {settings.map((i) => i.phone)}</b>
          </div>
        </div>
        {settings.find((i) => i.phone2) ? 
        <div className="col-lg-2 mb-3 col-6 mb-lg-0 cursor-pointer" onClick={() => window.open(`tel:${settings.map(i => i.phone2)}`)}>
          <div className="bg-main round text-center p-3 flex-column d-flex ">
          <i className="bi-telephone-fill  ms-2 " style={{fontSize: '21px'}}></i><b> {settings.map((i) => i.phone2)}</b>
          </div>
        </div>
        : ''}

      </div>

      <div className="google-map mt-5 bg-second position-relative p-3 round">
        <h3 className="mb-2 text-main">آدرس و موقعیت مکانی:</h3>
        <h5 className="mb-5">{settings.map((i) => i.address)} </h5>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6914868.971415689!2d48.395161767279376!3d32.19342739050545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef7ec2ec16b1df1%3A0x40b095d39e51face!2sIran!5e0!3m2!1sen!2s!4v1691226365415!5m2!1sen!2s"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

      </div> 

      <div className="mt-5 mb-2 text-center">تمامی حقوق مادی و معنوی این سایت متعلق به داینز|Dinez می‌باشد.</div>
    </div>
  );
};
export default Info;
